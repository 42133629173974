import React from 'react';
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import { ThemeContext } from '../../context/theme-context';

import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import Layout from '../../components/layout';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

const CasesPage = ({ location }) => {

    return (

        <Layout bg="white" location={location}>

            <Helmet>
                <script src={withPrefix('wrap.js')} type="text/javascript" />
                <title>ATUO - Cases - Fávori</title>
            </Helmet>

            <div className="container mx-auto">

                <Title
                    title={`Fávori <br /> Alimentos`}
                    titleColor="#FF0101"
                    description="O gostinho caseiro da confeitaria todo dia na sua mesa."
                    smallDescription="[artesanal, sabor, qualidade]"
                />

                <BannerImage src={`/favori/favori_content_one.jpg`} title="Fávori" />

                <IntroBlock
                    logo={`/favori/favori_logo.png`}
                    alt="Favori"
                    description="Destacar seus diferenciais se posicionando como marca de qualidade e sabor caseiro no segmento de produtos alimentícios congelados, aumento de market share e suporte na construção da marca."
                    list={['Estratégia & Inovacão', 'Pesquisa & Inteligência']} />

                <SimplePhrase>Qualidade e sabor caseiro destacados em um posicionamento rico em detalhes.</SimplePhrase>

                <div className="flex justify-center gap-x-16 md:px-24 items-center mb-40 md:my-40 flex-col md:flex-row">
                    <div className="md:w-1/2 text-2xl mt-10 md:mt-0">
                        <p className="text-2xl">O nome Fávori vem de favorito. As letras foram redesenhadas com inspiração nos desenhos feitos por mestres de confeitaria.</p>
                    </div>

                    <div className="flex items-center justify-center mt-24 md:mt-0">
                        <img src={`/favori/favori_stamp.png`} alt="Favori" className="inline w-8/12" />
                    </div>
                </div>

                <ChromaticPalette
                    description={"Os tons da marca seguem o arquétipo de Jung, trazendo poder e intimidade, conectando os consumidores ao gostinho caseiro dos produtos Fávori. As cores vivas servem também para instigar o apetite e gerar identificação visual."}
                >
                    <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:gap-x-32 justify-evenly md:justify-center">
                        <div className="cardColorBlock" data-aos="fade-up" data-aos-delay="0" style={{ backgroundColor: '#fe062c' }}>#fe062c</div>
                        <div className="cardColorBlock" data-aos="fade-up" data-aos-delay="300" style={{ backgroundColor: '#ffd400', color: '#000' }}>#ffd400</div>
                    </div>
                </ChromaticPalette>

                <div className="grid grid-cols-1 md:grid-cols-2 mt-40">
                    <div className="w-full flex flex-col justify-center">
                        <p className="text-2xl md:text-4xl mb-10 font-semibold md:leading-normal">Os produtos Fávori ganharam valorização e reconhecimento, tornando-se a melhor escolha para os melhores momentos à mesa.</p>

                        <p className="text-xl">A Fávori se tornou uma marca inspiradora e intimista, com uma comunicação que ressalta seus diferenciais de gostinho caseiro da marca que acompanha os melhores momentos em família.</p>
                    </div>

                    <div className="mt-24 md:mt-0 md:px-16">
                        <img src={`/favori/favori_content_two.jpg`} alt="Cervogia" />
                    </div>
                </div>

                <div className="text-center my-40 md:my-60">
                    <img src={`/favori/favori_content_three.png`} alt="Cervogia" className="md:w-2/4 inline" />
                </div>

                <div className="text-center mx-auto md:mx-40 mb-24 mt-40 md:my-60">
                    <p className="text-2xl md:text-4xl font-semibold leading-tight">Uma marca presente nos grandes momentos de muitas famílias</p>
                </div>

                <div className="flex flex-col md:flex-row mb-10 gap-y-10 md:gap-0">
                    <div><img src={`/favori/favori_content_four.jpg`} alt="Cervogia" /></div>
                    <div><img src={`/favori/favori_content_five.jpg`} alt="Cervogia" /></div>
                </div>
                <p className="text-right text-xl">[capricho do mestre confeiteiro]</p>

                <div className="flex flex-col md:flex-row items-center my-40 md:gap-x-4">
                    <p className="text-left text-2xl md:text-4xl mb-16 font-semibold md:leading-normal">A marca ganhou alma e propósito, ressaltados em suas embalagens e sua comunicação.</p>
                    <div>
                        <img src={`/favori/favori_content_six.png`} alt="Cervogia" />
                    </div>
                </div>
            </div>


            <BannerResult
                bg="#FF0101"
                list={['Posicionamento claro', 'Marca com propósito', 'Maior valorização dos produtos']} />

            <NextCase
                link="/cases/atimo"
                title="Átimo"
                description="Liberdade para viver os melhores momentos da vida."
                image="/atimo-next.png" />

            <ThemeContext.Consumer>
                {cx => (
                    <ContactBlock
                        description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <Footer />
        </Layout>
    );
};

export default CasesPage;
